/* style.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-image: url("/public/img/pexel.jpg");
  background-color: #101221;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #1e2633 !important;
}

.transparentAppBar {
  background: transparent;
  box-shadow: none;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
}

h1, h2, h3, h4, h5 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

p {
  font-family: "Roboto", sans-serif !important;
  font-weight: 320 !important;
  font-style: normal !important;
}

#projectsCarousel {
  padding-top: 70px;
  margin-bottom: 300px;
}

.home-button {
  text-decoration: none;
  color: inherit;
}

.carousel-image {
  max-height: 500px;  
  object-fit: cover;  
}

.custom-caption {
  top: 0;
  right: auto;  
  left: 15%;
  text-align: left;  
}

.home-button:hover {
  text-decoration: none;
}

#spacer {
  padding-bottom: 200px;
}

.contact-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;  
  min-height: 100vh;  
  padding-top: 40px;  
}

.footer {
  margin-top: auto; 
}

/* Import Roboto */
.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}